/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import { Styled } from "theme-ui"
import styled from "@emotion/styled"
import moment from "moment"
import BackgroundImage from "gatsby-background-image"
import {
  MdInfoOutline,
  MdBuild,
  MdQuestionAnswer,
  MdChevronRight,
  MdClose,
} from "react-icons/md"
import { uniqueid, truncate, uniqueId } from "lodash"
import theme from "@giraldomac/gatsby-theme-mmdbase/src/gatsby-plugin-theme-ui"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import {
  Hero,
  Overlay,
  SEO,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { CallToAction, CodeRed, QuickLinks } from "../components/elements"
import Announcement from "../components/elements/Announcement"

const BoxList = styled.ul`
  margin: 0;
  list-style: none;
  font-size: ${theme.fontSizes[2]};
  li {
    margin: 0;
    line-height: 1.25;
  }
  li a {
    align-items: center;
    color: white;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    justify-content: space-between;
    padding: 0.35em;
    text-decoration: none;
    transition: background-color 0.25s;
  }
  li a:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 200ms ease-in;
  }
`

const IndexPage = ({ data }) => {
  const emergency = data.prismic.allEmergency_noticess.edges
  const page = data.prismic.home

  const [state, setState] = useState(true)

  function toggle() {
    setState(!state)
  }

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <Announcement />

      {/* Hero */}
      <Hero>
        <BackgroundImage
          Tag="div"
          fluid={page.hero_imageSharp.childImageSharp.fluid}
        >
          <Container>
            <Box
              sx={{
                display: "block",
                position: "relative",
                py: [4, 5, 6],
                textAlign: "center",
                zIndex: 5,
              }}
            >
              {RichText.render(page.hero_text)}
            </Box>

            <Box
              sx={{
                display: "block",
                position: "relative",
                pb: 3,
                textAlign: "center",
                zIndex: 5,
              }}
            >
              <QuickLinks />
            </Box>
          </Container>
          <Overlay bg="gray.8" opacity="0.5" />
        </BackgroundImage>
      </Hero>

      <Section sx={{ pb: 0 }}>
        <Container>
          <Flex flexWrap="wrap">
            {/* Emergency Notice */}
            {emergency.map(({ node }) => {
              const d = new Date()
              const t = moment(d).format("L")
              const u = moment(node.unpublish_date).format("L")

              return (
                <React.Fragment>
                  {moment(u).isSameOrAfter(t) && state === true ? (
                    <Box
                      key={`${uniqueId("emergency_id_")}`}
                      width="full"
                      sx={{
                        backgroundColor: "red.2",
                        border: "1px solid",
                        borderColor: "red.5",
                        color: "red.7",
                        py: 3,
                        px: 4,
                        position: "relative",
                        mb: 4,
                      }}
                    >
                      <p className="text-lg" sx={{ fontWeight: "bold" }}>
                        {RichText.asText(node.title)}
                      </p>
                      <p>{`${truncate(RichText.asText(node.content), {
                        length: 180,
                        omission: " [...]",
                      })}`}</p>
                      <Link to={`notices/emergencies/${node._meta.uid}`}>
                        More details
                      </Link>

                      <MdClose
                        sx={{
                          cursor: "pointer",
                          position: "absolute",
                          fontSize: "1.5em",
                          top: "0.5em",
                          right: "0.5em",
                        }}
                        onClick={toggle}
                      />
                    </Box>
                  ) : null}
                </React.Fragment>
              )
            })}

            {/* Main Content */}
            <Box
              sx={{
                backgroundColor: "wrapperbox",
                padding: [4, 5],
                textAlign: "center",
              }}
            >
              {RichText.render(page.content)}
            </Box>
          </Flex>
        </Container>
      </Section>

      {/* Quick Links */}
      <Section>
        <Container>
          <Flex flexWrap="wrap">
            <Box
              width={["full", "full", 1 / 3]}
              sx={{
                backgroundColor: "yellow.6",
                color: "white",
              }}
            >
              <Flex flexWrap="wrap">
                <Box width="full" sx={{ p: 4, backgroundColor: "yellow.5" }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Styled.h2 sx={{ color: "white" }}>Notices</Styled.h2>
                    <MdInfoOutline sx={{ fill: "yellow.6", fontSize: "3em" }} />
                  </Flex>
                </Box>
                <Box width="full" sx={{ p: 4 }}>
                  <BoxList>
                    <li>
                      <Link to="notices/emergencies">
                        Emergency Notices{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="notices/general">
                        General Notices{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="notices/interruptions">
                        Service Interruptions{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                  </BoxList>
                </Box>
              </Flex>
            </Box>

            <Box
              width={["full", "full", 1 / 3]}
              sx={{
                backgroundColor: "red.6",
                color: "white",
              }}
            >
              <Flex flexWrap="wrap">
                <Box width="full" sx={{ p: 4, backgroundColor: "red.5" }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Styled.h2 sx={{ color: "white" }}>FAQs</Styled.h2>
                    <MdQuestionAnswer sx={{ fill: "red.6", fontSize: "3em" }} />
                  </Flex>
                </Box>
                <Box width="full" sx={{ p: 4 }}>
                  <BoxList>
                    <li>
                      <Link to="faqs/customer-service">
                        Customer Service{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="faqs/emergency">
                        Emergency Related{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="faqs/meter">
                        Meter Related{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="faqs/sewer">
                        Sewer Related{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="faqs/water">
                        Water Related{" "}
                        <span sx={{ fontSize: "inherit" }}>
                          <MdChevronRight />
                        </span>
                      </Link>
                    </li>
                  </BoxList>
                </Box>
              </Flex>
            </Box>

            <Box
              width={["full", "full", 1 / 3]}
              sx={{
                backgroundColor: "green.6",
                color: "white",
              }}
            >
              <Flex flexWrap="wrap">
                <Box width="full" sx={{ p: 4, backgroundColor: "green.5" }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Styled.h2 sx={{ color: "white" }}>Projects</Styled.h2>
                    <MdBuild sx={{ fill: "green.6", fontSize: "3em" }} />
                  </Flex>
                </Box>
                <Box width="full" sx={{ p: 4 }}>
                  <BoxList>
                    {data.prismic.allProjects.edges
                      .map(({ node }) => (
                        <li key={`${uniqueid("project_id_")}`}>
                          <Link to={`projects/${node._meta.uid}`}>
                            {RichText.asText(node.title)}{" "}
                            <span sx={{ fontSize: "inherit" }}>
                              <MdChevronRight />
                            </span>
                          </Link>
                        </li>
                      ))
                      .slice(0, 3)}
                  </BoxList>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Section>

      <CodeRed />

      <Container sx={{ textAlign: "center" }}>
        <a
          href="https://www.govdeals.com/PUB"
          target="_blank"
          rel="noopener noreferrer"
          title="Parkersburg Utility Board, WV online surplus auctions at https://www.govdeals.com"
        >
          <img
            src="https://www.govdeals.com/img/GovDeals_Logo.png"
            border="0"
            alt="https://www.govdeals.com/PUB"
            sx={{ maxWidth: "220px" }}
          />
        </a>
      </Container>

      <CallToAction />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    prismic {
      home(lang: "en-us", uid: "home") {
        title
        content
        hero_text
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        meta_title
        meta_description
        _linkType
      }
      allProjects(
        where: { project_satus_fulltext: "current" }
        sortBy: meta_lastPublicationDate_DESC
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
      allEmergency_noticess(first: 1) {
        edges {
          node {
            title
            content
            unpublish_date
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`
